<template>
  <div>
    <section class="static z-0 flex min-h-screen w-full flex-col bg-thm-active">
      <LayoutHeaderLoggedOut />

      <slot />

      <div class="h-32 w-full bg-thm-active sm:h-36 md:invisible" />

      <LayoutFooter style="flex-shrink: 0" />
    </section>
  </div>
</template>
